//
//
//
//

import { mapState, mapActions } from 'vuex';
export default {
	data() {
		return {};
	},
	methods: {}
};
